<template>
  <div style="text-align: center;width: 100%;margin-top: 40px">
    广州报告自动生成
  </div>
</template>

<script>

  export default {
    name: "GuangzhouReportGen",
    mounted() {
      this.genReport();
    },
    methods: {
      genReport() {
        this.$api.getDataT("gzReport/genReport/all", null, 100).then(res => {
          if (res.data.status == 0) {
            console.log(res.data.err);
          } else {
            if (res.data.data) {
              res.data.data.forEach(item => {
                this.gotoDetail(item);
              });
            }
          }
          window.close();
        }).catch(() => {

        });
      },
      gotoDetail(reportId) {
        window.open("/#/monitorReport/" + reportId + "?autoUpload=true");
      },
    },
  };
</script>

<style>

</style>
